
.course-content {
    padding: 20px;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    .select-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.carousel-dialog {
    width: 100%;
    height: 100%;
    .el-dialog {
        width: 560px;
        height: 360px;
        .carousel-uploader {
            width: 260px;
            height: 100px;
            background-color: #F4F5F5;
            ::v-deep .el-upload {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .img {
                    max-width: 100%;
                    max-height: 100%;
                }
                .el-icon-circle-plus {
                    color: #FF0013;
                    font-size: 26px;
                }
            }
        }
    }
}
.banner-img {
    display: block;
    height: 60px;
    img {
        display: inline-block;
        max-height: 100%;
    }
}
.prompt-msg {
    font-size: 13px;
    color: #666;
    line-height: 18px;
    span {
        color: #FF0013;
    }
}
